import * as $ from 'jquery'
// import popper from '@popperjs/core'
// import bootstrap from 'bootstrap'
// import { Tooltip, Toast, Popover } from 'bootstrap'
import 'jquery-ui/ui/core'
import 'jquery.easing'
import 'owl.carousel'
require("select2/dist/js/select2.full.min.js")
//const Handlebars = require("handlebars")
import 'script-loader!/node_modules/handlebars/dist/handlebars.min.js'

jQuery.fn.ForceNumericOnly = function() {
    return this.each(function() {
        $(this).keydown(function(e) {
            var key = e.charCode || e.keyCode || 0;
            // allow backspace, tab, delete, enter, arrows, numbers and keypad numbers ONLY
            // home, end, period, and numpad decimal
            return (
                key == 8 || 
                key == 9 ||
                key == 13 ||
                key == 46 ||
                key == 110 ||
                key == 190 ||
                (key >= 35 && key <= 40) ||
                (key >= 48 && key <= 57) ||
                (key >= 96 && key <= 105));
        });
    });
};

(function($){

	var $nWindowWidth = $(window).width(),
		$nWindowHeight = $(window).height(),
		$nClientWidth = Math.ceil($("body").prop("clientWidth")),
		$nClientHeight = Math.ceil($("body").prop("clientHeight")),
		templateWinnersTableScript,
		templateWinnersTable,
		arParallaxBaseSizesAndPositions = [],
		arParallaxCurrentSizesAndPositions = []

	$(document).ready(function() {

		$('.--input-numbers').ForceNumericOnly()

		if($("#winners-filter-form").length) {

			$(".filter-field__select").select2({
				minimumResultsForSearch: -1,
				width: '100%'
			})

			$('#filter-btn').on('click', function(event) {

				event.preventDefault();

				var thisForm = $(this).closest('form'),
					formData = thisForm.serialize()

				$.ajax({
					url: 'https://sbermarket.andreybazykin.com/',
					type: 'POST',
					data: formData,
					beforeSend: function() {
						thisForm.addClass('--loading')
						$('#winners-table-body').find('.table-body__row').remove()
						$('#winners-table-body').removeClass('--no-data')
						$('#winners-table-body').addClass('--loading')
						$('#winners-download-more-btn').addClass('d-none')
						$('#winners-download-more-btn').attr('data-page', 1)
						$('#winners-download-more-btn').attr('data-week', $(".filter-field__select").val())
					}
				})
				.done(function(data) {

					var response = $.parseJSON(data);

					if(response.data.length) {

						if(response.total_pages > 1)
							$('#winners-download-more-btn').removeClass('d-none')

						for(var i in response.data) {

							$('#winners-table-body').append(templateWinnersTable({
								date: response.data[i][0],
								number: response.data[i][1],
								name: response.data[i][2],
								prize: response.data[i][3]
							}))

						}

					}
					else {
						$('#winners-download-more-btn').addClass('d-none')
						$('#winners-table-body').addClass('--no-data')
					}

				})
				.fail(function() {
				})
				.always(function() {
					thisForm.removeClass('--loading')
					$('#winners-table-body').removeClass('--loading')
				})
				
			})

			$('#winners-download-more-btn').on('click', function(event) {

				event.preventDefault();

				if($('#winners-table-body').hasClass('--loading'))
					return false;

				var thisBtn = $(this),
					nCurrentPage = parseInt(thisBtn.attr('data-page')),
					nNextPage = nCurrentPage + 1,
					sCurrentWeek = thisBtn.attr('data-week')

				$.ajax({
					url: 'https://sbermarket.andreybazykin.com/',
					type: 'POST',
					data: {
						filter_week: sCurrentWeek,
						page: nNextPage
					},
					beforeSend: function() {
						$('#winners-table-body').addClass('--loading')
					}
				})
				.done(function(data) {

					var response = $.parseJSON(data);

					if(response.data.length) {

						for(var i in response.data) {

							$('#winners-table-body').append(templateWinnersTable({
								date: response.data[i][0],
								number: response.data[i][1],
								name: response.data[i][2],
								prize: response.data[i][3]
							}))

						}

						if(nNextPage >= response.total_pages )
							$('#winners-download-more-btn').addClass('d-none')
						else
							thisBtn.attr('data-page', nNextPage)

					}
					else
						thisBtn.addClass('d-none')


				})
				.fail(function() {
				})
				.always(function() {
					$('#winners-table-body').removeClass('--loading')
				})
				
			})

		}

		$('.hamburger').on('click', function(event) {
			event.preventDefault();
			$(this).toggleClass('is-active')
			$('body').toggleClass('--nav-show')
		});

		$('.home-win-prize__button a').on('click', function(event) {
			event.stopPropagation()
			var thisBtn = $(this)
			navigator.clipboard.writeText(thisBtn.data('promo-code'))
			//console.log(thisBtn.data('promo-code'))
			//window.location.href = thisBtn.attr('href')
			//window.open(thisBtn.attr('href'), '_blank').focus();
			//return false;
		});

		if($(".faq__accordion-item").length > 0) {

			$(".faq__accordion-item > .accordion-item__head").on('click', function(event) {

				event.preventDefault()
				var $this = $(this);

				if($this.parent().hasClass('--active'))
					return false;

				$(".faq__accordion-item").removeClass('--active')
				$this.parent().addClass('--active')

			})

		}

		$('a[data-scroll-to]').on('click', function(){
			const destination = $("a[name='"+ $(this).data('data-scroll-to') +"']");
			$('html,body').animate({
				scrollTop: destination.offset().top
			}, 2000)
		});

		if($(".owl-carousel.--home-logos").length > 0) {

			var $owlCarouselHomeLogos = $(".owl-carousel.--home-logos").owlCarousel({
				loop: true,
				autoplay: true,
				autoplayHoverPause: false,
				/*autoplayTimeout: 0,
				autoplaySpeed: 3000,*/
				slideTransition: 'linear',
				autoplayTimeout: 2000,
	    		smartSpeed: 2000,
				margin: 25,
				stagePadding: 25,
				/*center: true,
				singleItem: true,
				autoWidth: true,*/
				nav: false,
				dots: false,
				mouseDrag: false,
				touchDrag: false,
				items: 1,
				responsive : {
					0: {
						items: 1,
					},
					576: {
						items: 1,
					},
					768: {
						items: 3,
					},
					992: {
						items: 3,
					},
					1440: {
						items: 4,
					},
					1920: {
						items: 6,
					},
					2560: {
						items: 8,
					}
				}
			})

		}

		if($(".owl-carousel.--home-prizes").length > 0) {

			var $owlCarouselHomePrizes = $(".owl-carousel.--home-prizes").owlCarousel({
				loop: true,
				autoplay: false,
				margin: 29,
				stagePadding: 10,
				nav: true,
				navText: ["<div class='owl__nav-left'></div>","<div class='owl__nav-right'></div>"],
				dots: true,
				items: 3,
				responsive : {
					0: {
						items: 1
					},
					576: {
						items: 1
					},
					768: {
						items: 1
					},
					992: {
						items: 2
					},
					1170: {
						items: 3
					}
				}
			})

		}
	
	})

	$(window).on('load', function() {

		$nWindowWidth = $(window).width()
		$nWindowHeight = $(window).height()
		$nClientWidth = Math.ceil($("body").prop("clientWidth"))
		$nClientHeight = Math.ceil($("body").prop("clientHeight"))

		var objCarLights = $('.text-col__car-light.--left, .text-col__car-light.--right, .car-lights__left, .car-lights__right')

		objCarLights.addClass('--car-light-blink-twice')

		setTimeout(function() {
			objCarLights.addClass('--car-light-blink-secondly')
		}, 2000)


		if($('.parallax-vegetables').length) {

			$('.parallax-vegetables > .veg').each(function(index, element) {
				arParallaxBaseSizesAndPositions.push([parseInt($(element).width()), parseInt($(element).css('marginTop')), parseInt($(element).css('marginLeft'))])
			})

			initVegetablesParallax()

		}

		initWinnersData()

	})

	$(window).on('resize', function(event) {
		$nWindowWidth = $(window).width()
		$nWindowHeight = $(window).height()
		$nClientWidth = Math.ceil($("body").prop("clientWidth"))
		$nClientHeight = Math.ceil($("body").prop("clientHeight"))
		if($('.parallax-vegetables').length) initVegetablesParallax()
	})

	$(window).on('scroll', function(event) {
		if($('.parallax-vegetables').length) updateVegetablesParallax()
	})

	function initWinnersData() {

		if($("#winners-filter-form").length) {

			templateWinnersTableScript = document.getElementById('winners-table').innerHTML,
			templateWinnersTable = Handlebars.compile(templateWinnersTableScript);

			$.ajax({
				url: 'https://sbermarket.andreybazykin.com/',
				type: 'POST',
				data: {
					filter_week: $(".filter-field__select").val()
				}
			})
			.done(function(data) {

				var response = $.parseJSON(data);

				if(response.data.length) {

					for(var i in response.data) {

						$('#winners-table-body').append(templateWinnersTable({
							date: response.data[i][0],
							number: response.data[i][1],
							name: response.data[i][2],
							prize: response.data[i][3]
						}))

					}

				}
				else {
					$('#winners-download-more-btn').addClass('d-none')
					$('#winners-table-body').addClass('--no-data')
				}

			})
			.fail(function() {
			})
			.always(function() {
				$('#winners-table-body').removeClass('--loading')
			});

		}

	}	

	function initVegetablesParallax() {
		
		arParallaxCurrentSizesAndPositions = []

		$('.parallax-vegetables > .veg').each(function(index, element) {

			if($nWindowWidth <= 992 && $nWindowWidth > 768)
				$(element).css({
					width: parseInt(arParallaxBaseSizesAndPositions[index][0] * 0.66) + "px",
					marginTop: parseInt(arParallaxBaseSizesAndPositions[index][1] * 0.66) + "px",
					marginLeft: parseInt(arParallaxBaseSizesAndPositions[index][2] * 0.66) + "px",
				})
			else if($nWindowWidth <= 768)
				$(element).css({
					width: parseInt(arParallaxBaseSizesAndPositions[index][0] * 0.5) + "px",
					marginTop: parseInt(arParallaxBaseSizesAndPositions[index][1] * 0.5) + "px",
					marginLeft: parseInt(arParallaxBaseSizesAndPositions[index][2] * 0.5) + "px",
				})
			else
				$(element).css({
					width: parseInt(arParallaxBaseSizesAndPositions[index][0]) + "px",
					marginTop: parseInt(arParallaxBaseSizesAndPositions[index][1]) + "px",
					marginLeft: parseInt(arParallaxBaseSizesAndPositions[index][2]) + "px",
				})

			arParallaxCurrentSizesAndPositions.push([parseInt($(element).width()), parseInt($(element).css('marginTop')), parseInt($(element).css('marginLeft'))])

		});

		updateVegetablesParallax()

	}

	function updateVegetablesParallax() {

		if(arParallaxCurrentSizesAndPositions.length == 0) return false;

		var nScrollTop = $(window).scrollTop(),
			nVegetablesParallaxBlockHeight = $(".parallax-vegetables").height(),
			nStartParallaxPosition = $(".parallax-vegetables").position().top,
			nEndParallaxPosition = $(".parallax-vegetables").position().top + nVegetablesParallaxBlockHeight

		// Объект находится в зоне экрана
		if(
			((nScrollTop+$nWindowHeight) > nStartParallaxPosition) &&
			(nScrollTop < nEndParallaxPosition)
		) {
			$('.parallax-vegetables > .veg').each(function(index, element) {
				$(element).css({
					marginTop: arParallaxCurrentSizesAndPositions[index][1] - ((nScrollTop + $nWindowHeight - nEndParallaxPosition - nVegetablesParallaxBlockHeight) * parseFloat($(element).attr('data-speed'))) + 'px'
				})

			})
		}

	}

})(jQuery)